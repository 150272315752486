<template>
  <b-container>
    <b-form>
      <b-card bg-variant="light" :title="$t('export.headline')" footer-class="text-right" footer-bg-variant="light">
        <b-form-group
          v-if="isAdmin"
          id="fieldset-2"
          label-for="multiUserSelect"
          :label="$t('export.users')"
          label-cols="4"
          label-cols-lg="3"
        >
          <multiselect
            id="multiUserSelect"
            v-model="selectedUsers"
            :options="allUsers"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :hide-selected="false"
            :placeholder="$t('components.multiselect.users.placeholder')"
            :show-no-results="false"
            track-by="text"
            label="text"
            group-label="label"
            group-values="options"
            :group-select="true"
            :select-label="$t('components.multiselect.select-label')"
            :select-group-label="$t('components.multiselect.select-group-label')"
            :selected-label="$t('components.multiselect.selected-label')"
            :deselect-label="$t('components.multiselect.deselect-label')"
            :deselect-group-label="$t('components.multiselect.deselect-group-label')"
          />
        </b-form-group>

        <b-form-group label-for="date-pick-start" :label="$t('datetime.timespan')" label-cols="4" label-cols-lg="3">
          <b-row>
            <b-col cols="4">
              <Datepicker
                id="date-pick-start"
                class="datePicker"
                v-model="datePickStart"
                :locale="this.$store.getters.getLocale"
                :placeholder="this.$t('datetime.start-date')"
              />
            </b-col>
            <b-col cols="4" class="ml-2">
              <Datepicker
                id="date-pick-end"
                class="datePicker"
                v-model="datePickEnd"
                :locale="this.$store.getters.getLocale"
                :placeholder="this.$t('datetime.end-date')"
                :min="datePickStart"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <DatepickerQuickfilter
                v-on:datePickStart="datePickStart = $event"
                v-on:datePickEnd="datePickEnd = $event"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <template #footer>
          <b-button @click="downloadCSV()" variant="primary" :disabled="disableSubmitButton"
            >{{ $t('export.submit') }}
          </b-button>
        </template>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import Multiselect from 'vue-multiselect'
import Datepicker from '@/components/GoldflamDatepicker'
import DatepickerQuickfilter from '@/components/DatepickerQuickfilter'
import { UserUtil } from '@/util/UserUtil'

export default {
  name: 'ManageExport',
  mixins: [ApiMixin, NotificationMixin],
  components: { Datepicker, Multiselect, DatepickerQuickfilter },
  data() {
    return {
      allUsers: [],
      selectedUsers: [],
      datePickStart: '',
      datePickEnd: ''
    }
  },
  computed: {
    disableSubmitButton() {
      return this.datePickStart === '' || this.datePickEnd === ''
    }
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      let self = this
      this.getRequest(
        '/users',
        new RequestConfig().onSuccess(res => {
          self.allUsers = UserUtil.getSelectOptsForUserListGroupedByStatus(res.data)
        })
      )
    },
    downloadCSV() {
      let self = this
      let url = ''
      if (this.selectedUsers.size === 0) {
        url = this.linkForSelfExport()
      } else {
        url = this.linkForOtherUserExport()
      }
      this.postRequest(
        url,
        null,
        new RequestConfig()
          .withAxiosConfig({ responseType: 'arraybuffer' })
          .onSuccess(res => {
            const blob = new Blob([res.data], { type: 'text/csv' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            // parse filename out of content-dispo header, default file.csv
            link.download = 'file.csv'
            const disposition = res.headers['content-disposition']
            if (disposition && disposition.indexOf('attachment') !== -1) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              const matches = filenameRegex.exec(disposition)
              if (matches != null && matches[1]) {
                link.download = matches[1].replace(/['"]/g, '')
              }
            }
            link.click()
            URL.revokeObjectURL(link.href)
            link.remove()

            self.clearData()
          })
          .onError(err => {
            // err.response.data is an arraybuffer because of the request response type, need to change it to string
            const res = JSON.parse(Buffer.from(err.response.data).toString('utf8'))
            if (res.message === 'missing-review-timetracking-units') {
              self.displayError(self.$t('export.missing-review-timetracking-units'))
            } else {
              self.displayError(res.message)
            }
          })
      )
    },
    linkForSelfExport() {
      return '/export?startDate=' + this.datePickStart + '&endDate=' + this.datePickEnd
    },
    linkForOtherUserExport() {
      let url = '/export/differentUsers?startDate=' + this.datePickStart + '&endDate=' + this.datePickEnd
      this.selectedUsers.forEach(user => (url += '&userIds=' + user.value.id))
      return url
    },
    clearData() {
      this.datePickStart = ''
      this.datePickEnd = ''
      this.selectedUsers = []
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
